import React, { useState, useEffect } from 'react';

//Material UIs
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

//Icons
import ReportRoundedIcon from '@mui/icons-material/ReportRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

//Constants
import * as apiConstants from '../../constants/apiConstants';

//Others
import moment from 'moment';

function HomePage() {
    //const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [latestUpdate, setLatestUpdate] = useState();
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetch(`${apiConstants.FINDPARKING_API_HOSTNAME}/slot/HRH`, { crossDomain: true })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                    setLatestUpdate(result[0].lastOnline);
                },
                (error) => {
                    //setIsLoaded(true);
                    console.log(error);
                }
            )

        getParkingInfo();
    }, [])

    const getParkingInfo = () => {
        try {
            setInterval(async () => {
                await fetch(`${apiConstants.FINDPARKING_API_HOSTNAME}/slot/HRH`, { crossDomain: true })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setIsLoaded(true);
                            setItems(result);
                            console.log(result[0]);
                            setLatestUpdate(result[0].lastOnline);
                        },
                        (error) => {
                            //setIsLoaded(true);
                            console.log(error);
                        }
                    )
            }, 5000);
        } catch (e) {
            let result = [{ currentslots: 'error' }]
            setItems(result);
            console.log(e);

        }
    }

    const statusColor = (current, total) => {
        const percentage = (current / total) * 100;

        if (percentage >= 25) {
            return "#81C799"
        } if (25 > percentage && percentage > 5) {
            return "#FECD70"
        } if (5 >= percentage && percentage >= 0) {
            return "#B83232"
        }
    }

    return (
        <Container maxWidth="md">
            {/* ========================== MOBILE ========================== */}
            <Grid item sx={{ display: { sm: 'none', xs: 'block' } }} xs={12} style={{ paddingLeft: "2%", paddingRight: "2%", paddingTop: "5%" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-end" spacing={1}>
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <SquareRoundedIcon style={{ color: "#81C799" }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            {">25%"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <SquareRoundedIcon style={{ color: "#FECD70" }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            {"<25%"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <SquareRoundedIcon style={{ color: "#B83232" }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            {"<5%"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item align="center" xs={12}>
                        <Paper
                            elevation={0}
                            style={{
                                padding: '20px',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                backgroundColor: '#C2C2C2',
                                borderRadius: '15px'
                            }} >
                            <Grid container alignItems="center">
                                <Grid item xs={6}>
                                    <Typography variant="h5"
                                        style={{ fontWeight: 700, color: 'white' }}
                                    >
                                        Level
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h5"
                                        style={{ fontWeight: 700, color: 'white' }}
                                    >
                                        Available*
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {isLoaded && items.map((obj, index) =>
                        <Grid key={`mobile-${index}`} item align="center" xs={12}>
                            <Paper
                                elevation={0}
                                style={{
                                    padding: '20px',
                                    paddingTop: '30px',
                                    paddingBottom: '30px',
                                    backgroundColor: '#EBEBEB',
                                    borderRadius: '15px'
                                }} >
                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        <Typography variant="h4"
                                            style={{ fontWeight: 700 }}
                                        >
                                            {obj.floor === "P4" ? "P4&P5" : obj.floor}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                {obj.isAvailable ? <Button
                                                    disabled
                                                    disableElevation
                                                    variant="contained"
                                                    style={{
                                                        color: "white",
                                                        backgroundColor: obj.currentslots <= 0 ? "#D1D1D1" : statusColor(obj.currentslots, obj.totalslots)  /*obj.currentslots === 0 ? "#B83232" : "#81C799"*/,
                                                        width: '100px',
                                                        borderRadius: '10px'
                                                    }}
                                                >
                                                    <Typography variant="h5" style={{ fontWeight: 600 }}>
                                                        {obj.currentslots <= 0 ? "Full" : (obj.currentslots < 2 ? "<2" : obj.currentslots)}
                                                    </Typography>
                                                </Button> : <Button
                                                    disabled
                                                    disableElevation
                                                    variant="contained"
                                                    style={{
                                                        color: "#ffcc00",
                                                        backgroundColor: "black",
                                                        borderRadius: '10px',
                                                        textTransform: "none",
                                                        borderWidth: "5px 0 5px 0",
                                                        borderStyle: "solid",
                                                        borderImage: "repeating-linear-gradient( 45deg, #ffcc00, #ffcc00 10px, black 10px, black 20px) 10"
                                                    }}
                                                    startIcon={<ReportProblemRoundedIcon style={{ color: "#ffcc00" }} />}
                                                >
                                                    <Typography variant="body2"
                                                        style={{ fontWeight: 700 }}
                                                    >
                                                        Maintenance
                                                    </Typography>
                                                </Button>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {obj.floor === "B1" && <Grid item align="center" xs={12} style={{ marginTop: "5px" }}>
                                        <Typography variant="caption" style={{ marginTop: "5px", color: 'rgb(242 3 3)' }}>
                                            (B1 includes non-EM parking slots)
                                        </Typography>
                                    </Grid>}
                                </Grid>
                            </Paper>
                        </Grid>
                    )}


                    {isLoaded &&
                        <Grid container>
                            <Grid item align="right" xs={12} style={{ paddingLeft: '5%', paddingTop: '5px', color: '#C2C2C2' }}>
                                <Typography variant="body2" style={{ fontWeight: 600 }}>
                                    {`Last activity ${moment(latestUpdate).startOf('minutes').fromNow() === 'a few seconds ago' ? 'less than 1 minute ago' : moment(latestUpdate).startOf('minutes').fromNow()}`}
                                </Typography>
                            </Grid>
                            <Grid item align="center" xs={12} style={{ paddingLeft: '5%', paddingTop: '5px', color: 'rgb(242 3 3)' }}>
                                <Typography variant="body2" style={{ fontWeight: 600 }}>
                                    *The number includes parallel parking slots.
                                </Typography>
                                <Typography variant="body2" style={{ fontWeight: 600 }}>
                                    **Due to environmental condition, data may be inaccurate.
                                </Typography>
                            </Grid>
                        </Grid>
                    }

                    {!isLoaded &&
                        <Grid item align="center" xs={12}>
                            <Skeleton variant="rounded" height={110} style={{ borderRadius: '15px' }} />
                        </Grid>
                    }

                    <Fab
                        variant="extended"
                        size="medium"
                        href="https://app.sli.do/event/m8g53EBjxR4bpju2bN9cWm"
                        target="_blank"
                        sx={{
                            position: 'fixed',
                            bottom: (theme) => theme.spacing(2),
                            right: (theme) => theme.spacing(2),
                            backgroundColor: "#CE0A0A",
                            color: "white",
                            "&:hover": {
                                backgroundColor: "#ab0707"
                            }
                        }}>
                        <ReportRoundedIcon sx={{ mr: 1 }} />
                        Report
                    </Fab>
                </Grid>
            </Grid>

            {/* ========================== WEB ========================== */}
            <Grid item sx={{ display: { xs: 'none', sm: 'block' } }} xs={12} style={{ paddingTop: "40px" }}>
                <Grid container justify="center" spacing={1}>
                    <Grid item align="center" xs={12}>
                        <Grid container alignItems="flex-end" spacing={2}>
                            <Grid item xs={10.5}>
                                <Paper variant="outlined" elevation={0} style={{
                                    padding: '20px',
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                    //backgroundColor: '#C2C2C2',
                                    borderRadius: '15px'
                                }}>
                                    <Grid container alignItems="center">
                                        <Grid item align="center" sm={12} md={4}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 230 }}
                                                image="/qrcode_findparking.smartoffice.xom.cloud.png"
                                            />
                                        </Grid>
                                        <Grid item xs alignItems="flex">
                                            <Typography variant="h5" style={{ fontWeight: 600 }}>
                                                Accessible on any device via
                                            </Typography>
                                            <Typography variant="h3" style={{ fontWeight: 700, color: "#CE0A0A" }}>
                                                bit.ly/findparking
                                            </Typography>
                                            <Typography variant="p" style={{ fontWeight: 500, color: "rgb(193 193 195)", paddingLeft: 0, paddingTop: '15px' }}>
                                                *This is a pilot project, subject to minor data inaccuracy.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs>
                                <Grid container>
                                    <Grid item align="right" xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <SquareRoundedIcon style={{ color: "#81C799" }} />
                                            </Grid>
                                            <Grid item>
                                                <Typography>
                                                    {">25%"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <SquareRoundedIcon style={{ color: "#FECD70" }} />
                                            </Grid>
                                            <Grid item>
                                                <Typography>
                                                    {"<25%"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <SquareRoundedIcon style={{ color: "#B83232" }} />
                                            </Grid>
                                            <Grid item>
                                                <Typography>
                                                    {"<5%"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item align="center" xs={12}>
                        <Paper
                            elevation={0}
                            style={{
                                padding: '20px',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                backgroundColor: '#C2C2C2',
                                borderRadius: '15px'
                            }} >
                            <Grid container alignItems="center">
                                <Grid item xs={6}>
                                    <Typography variant="h4"
                                        style={{ fontWeight: 700, color: 'white' }}
                                    >
                                        Level
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h4"
                                        style={{ fontWeight: 700, color: 'white' }}
                                    >
                                        Available*
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {isLoaded && items.map((obj, index) =>
                        <Grid key={`web-${index}`} item align="center" xs={12}>
                            <Paper
                                elevation={0}
                                style={{
                                    padding: '20px',
                                    paddingTop: '30px',
                                    paddingBottom: '30px',
                                    backgroundColor: '#EBEBEB',
                                    borderRadius: '15px'
                                }} >
                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        <Typography variant="h4"
                                            style={{ fontWeight: 700 }}
                                        >
                                            {obj.floor === "P4" ? "P4&P5" : obj.floor}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {obj.isAvailable ? <Button
                                            disabled
                                            disableElevation
                                            variant="contained"
                                            style={{
                                                color: "white",
                                                backgroundColor: obj.currentslots <= 0 ? "#D1D1D1" : statusColor(obj.currentslots, obj.totalslots),
                                                width: '100px',
                                                borderRadius: '10px'
                                            }}
                                        >
                                            <Typography variant="h5" style={{ fontWeight: 600 }}>
                                                {obj.currentslots <= 0 ? "Full" : (obj.currentslots < 2 ? "<2" : obj.currentslots)}
                                            </Typography>
                                        </Button> : <Button
                                            disabled
                                            disableElevation
                                            variant="contained"
                                            style={{
                                                color: "#ffcc00",
                                                backgroundColor: "black",
                                                borderRadius: '10px',
                                                textTransform: "none",
                                                borderWidth: "5px 0 5px 0",
                                                borderStyle: "solid",
                                                borderImage: "repeating-linear-gradient( 45deg, #ffcc00, #ffcc00 10px, black 10px, black 20px) 10"
                                            }}
                                            startIcon={<ReportProblemRoundedIcon style={{ color: "#ffcc00" }} />}
                                        >
                                            <Typography variant="h6"
                                                style={{ fontWeight: 700 }}
                                            >
                                                Under maintenance
                                            </Typography>
                                        </Button>}
                                        {obj.floor === "B1" && <Typography variant="body2" style={{ marginTop: "5px", color: 'rgb(242 3 3)' }}>
                                            (B1 includes non-EM parking slots)
                                        </Typography>}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    )}
                    {isLoaded &&
                        <Grid item align="center" xs={12}>
                            <Grid container>
                                <Grid item align="left" xs style={{ color: 'rgb(242 3 3)' }}>
                                    <Typography variant="body1" style={{ fontWeight: 600 }}>
                                        *The number includes parallel parking slots
                                    </Typography>
                                    <Typography variant="body1" style={{ fontWeight: 600 }}>
                                        **Due to environmental condition, data may be inaccurate.
                                    </Typography>
                                </Grid>
                                <Grid item align="right" style={{ color: '#C2C2C2' }}>
                                    <Typography variant="body1" style={{ fontWeight: 600 }}>
                                        {`Last activity ${moment(latestUpdate).startOf('minutes').fromNow() === 'a few seconds ago' ? 'less than 1 minute ago' : moment(latestUpdate).startOf('minutes').fromNow()}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    }


                    {!isLoaded &&
                        <Grid item align="center" xs={12}>
                            <Skeleton variant="rounded" height={105} style={{ borderRadius: '15px' }} />
                        </Grid>
                    }
                    <Fab
                        variant="extended"
                        color="primary"
                        href="https://app.sli.do/event/m8g53EBjxR4bpju2bN9cWm"
                        target="_blank"
                        sx={{
                            position: 'fixed',
                            bottom: (theme) => theme.spacing(2),
                            right: (theme) => theme.spacing(2),
                            backgroundColor: "#CE0A0A",
                            color: "white",
                            "&:hover": {
                                backgroundColor: "#ab0707"
                            }
                        }}>
                        <ReportRoundedIcon sx={{ mr: 1 }} />
                        Report an issue
                    </Fab>
                </Grid>
            </Grid>
        </Container>
    );
}

export default HomePage;
