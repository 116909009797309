import React, { useEffect, useState } from "react";
import Chart from 'chart.js/auto'
import Container from "@mui/material/Container";
import { LineChart } from "../shared/lineChart";
import * as apiConstants from "../../constants/apiConstants";

const P4slot = 175
const B1slot = 99
const B2slot = 131

const ChartPage = () => {
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState([{
    label:"P4",
    datasets: []
},
{
    label:"B1",
    datasets: []
},
{
    label:"B2",
    datasets: []
}])

  const getLogData = () => {
    const enddatetime = new Date();
    const startdatetime = new Date(
      new Date().setDate(enddatetime.getDate() - 1)
    );
    console.log(enddatetime);
    console.log(startdatetime);
    return fetch(
      `${
        apiConstants.FINDPARKING_API_HOSTNAME
      }/filterlogs?startdatetime=${startdatetime.toISOString()}&enddatetime=${enddatetime.toISOString()}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setData(result);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const LineChartDataGenerator = () => {
        let result = [{
            label:"P4",
            data: [],
        },
        {
            label:"B1",
            data: [],
        },
        {
            label:"B2",
            data: [],
        }]
        data.forEach((detail)=> {
            switch (detail.floor){
            case "P4":
                result[0].data.push({x:detail.timestamp, y:(((P4slot - detail.currentslots)/P4slot) * 100)})
                break
            case "B1":
                result[1].data.push({x:detail.timestamp, y:(((B1slot - detail.currentslots)/B1slot) * 100)})
                break
            case "B2":
                result[2].data.push({x:detail.timestamp, y:(((B2slot - detail.currentslots)/B2slot) * 100)})
                break
            }
        })

        return result

  } 

  useEffect(() => {
    getLogData();
  }, []);

  useEffect(() => {
    let a = LineChartDataGenerator() 
    setChartData(a)
  },[data])


  return (
    <Container maxWidth="md">
      <LineChart data={{
        datasets: chartData,
      }} />
    </Container>
  );
};

export default ChartPage;
