import React, { useState, useEffect } from 'react';

//Material UI
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { headerLogReport, headerReport } from '../../constants/reportConstants';

//Components
import { InputContainer } from '../shared/inputSlotBox';

//Constants
import * as apiConstants from '../../constants/apiConstants';

//Others
import axios from "axios"
import moment from 'moment';
import { exportExcel } from '../shared/exportToExcel';
// import ReactExport from 'react-export-excel'

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const theme = createTheme({
  typography: {
    fontFamily: [
      "Poppins"
    ].join(",")
  }
});

function UpdateSlotCount(props) {
  //const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isIncorrectPasscode, setIsIncorrectPasscode] = useState(false);
  const [isSlotCountInputEmpty, setIsSlotCountInputEmpty] = useState(false);
  const [latestUpdate, setLatestUpdate] = useState();
  const [items, setItems] = useState([]);
  const [passcodeInput, setPasscodeInput] = useState("");
  const [slotCountInputP4, setSlotCountInputP4] = useState();
  const [slotCountInputP5, setSlotCountInputP5] = useState();
  const [slotCountInputB2, setSlotCountInputB2] = useState();
  const [selectedSubmit, setSelectedSubmit] = useState([]);
  const [reportData, setReportData] = useState([{}])

  const passcode = "1212312121"

  const P4slot = 101
  const P5slot = 79
  const B2slot = 131

  function formatDate(date, langCode) {
    var day = date.toLocaleString(langCode, { day: '2-digit' });   // DD
    var month = date.toLocaleString(langCode, { month: 'short' }); // MMM
    var year = date.toLocaleString(langCode, { year: 'numeric' }); // YYYY
    return `${day} ${month} ${year}`;
  }

  useEffect(() => {
    fetch(`${apiConstants.FINDPARKING_API_HOSTNAME}/slot/HRH`, { crossDomain: true })
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          console.log(result)
          setItems(result);
          setLatestUpdate(result[0].lastOnline);
        },
        (error) => {
          setIsLoaded(true);
          console.log(error);
        }
      )

    getReportData();
    getParkingInfo();
  }, [])

  useEffect(() => {
    console.log(reportData)
  }, [reportData])

  const getParkingInfo = () => {
    try {
      setInterval(async () => {

        await fetch(`${apiConstants.FINDPARKING_API_HOSTNAME}/slot/HRH`, { crossDomain: true })
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setItems(result);
              setLatestUpdate(result[0].lastOnline);
            },
            (error) => {
              //setIsLoaded(true);
              console.log(error);
            }
          )

      }, 5000);
    } catch (e) {
      console.log(e);
    }
  }

  const getLogData = () => {
    setIsLoaded(false)
    const enddatetime = new Date();
    const startdatetime = new Date(
      new Date().setDate(enddatetime.getDate() - 30)
    );
    console.log(enddatetime);
    console.log(startdatetime);
    return fetch(
      `${
        apiConstants.FINDPARKING_API_HOSTNAME
      }/filterlogs?startdatetime=${startdatetime.toISOString()}&enddatetime=${enddatetime.toISOString()}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          return result
        },
        (error) => {
          setIsLoaded(true);
          console.log(error);
        }
      );
  };

  const getReportData = async () => {
    try {
      fetch(`${apiConstants.FINDPARKING_API_HOSTNAME}/reports`)
        .then(res => res.json())
        .then(
          (result) => {
            setReportData(result);
            console.log(result)
          },
          (error) => {
            setIsLoaded(true);
            console.log(error);
          }
        )
    } catch (e) {
      console.log(e);
    }
  }

  const statusColor = (current, total) => {
    const percentage = (current / total) * 100;

    if (percentage >= 25) {
      return "#81C799"
    } if (25 > percentage && percentage > 5) {
      return "#FECD70"
    } if (5 >= percentage && percentage >= 0) {
      return "#B83232"
    }
  }

  const handleOpenDialog = (floors) => {
    if (floors) {
      setIsSlotCountInputEmpty(false);
      setIsOpenDialog(true);
    } else {
      setIsSlotCountInputEmpty(true);
      setIsOpenDialog(false);
    }

    setSelectedSubmit(floors)

    return;
  }

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  }

  const onChageSlotCount = (event) => {
    setSlotCountInputP4(event.target.value < 0 ? 0 : event.target.value);
    setIsSlotCountInputEmpty(false);
  }

  const onChagePasscodeInput = (event) => {
    setPasscodeInput(event.target.value);
    setIsIncorrectPasscode(false);
  }

  const generateReportData = (reportData) => {
    const reportDatas = reportData.map((data) => {
      let slotCount;
      if (data.floor === "P4") {
        slotCount = P4slot
      } else if (data.floor === "P5") {
        slotCount = P5slot
      } else if (data.floor === "B2") {
        slotCount = B2slot
      }

      data.percentage = Math.floor((data.countslots / slotCount) * 100)
      const d = new Date(data.timestamp)
      data.date = formatDate(d, 'en')
      data.time = d.toLocaleTimeString("en-TH", { hour12: false })
      return data
    })

    return reportDatas
  }

  const generateLogFileData = async () => {

      let result = await getLogData()
      exportExcel([result], [headerLogReport], ['reportSheet'], 'report')
      setIsLoaded(true)
  }

  const updateReport = async (floorNumber, slotCountInput) => {
    const res = await axios({
      method: 'put',
      url: 'https://findparking-api-dev.azurewebsites.net/updatereport',
      data: {
        building: "HRH",
        floor: floorNumber,
        number: slotCountInput
      }
    })

    return res
  }

  const updateSlot = async (floorNumber, slotCountInput) => {
    const res = await axios({
      method: 'put',
      url: 'https://findparking-api-dev.azurewebsites.net/updateslot',
      data: {
        building: "HRH",
        floor: floorNumber,
        number: slotCountInput
      }
    })

    return res
  }

  const handleVerifyPasscode = async () => {
    try {
      if (passcodeInput === passcode) {
        setIsUpdating(true);

        let resultReport = selectedSubmit.map(async floor => {
          let res = updateReport(floor.number, Number(floor.slotCountInput))
          return res
        });

        console.log(resultReport)

        let total
        if (selectedSubmit.length === 2) {
          total = Number(selectedSubmit[0].slotCountInput) + Number(selectedSubmit[1].slotCountInput)
        } else {
          total = Number(selectedSubmit[0].slotCountInput)
        }
        let resultSlot = updateSlot(selectedSubmit[0].number, total)

        console.log(resultSlot)

        await Promise.all(resultReport, resultSlot)


        handleCloseDialog();
        setIsIncorrectPasscode(false)

        setIsUpdating(false);
        setSlotCountInputB2(0)
        setSlotCountInputP4(0)
        setSlotCountInputP5(0)
        return;
      } else {
        setIsIncorrectPasscode(true);
        setIsUpdating(false);
        return;
      }
    } catch (error) {

      console.log(error)
      setIsUpdating(false);
      setIsIncorrectPasscode(true)
    }
  }

  const onClickAvilableSwitch = async (obj) => {
    await axios({
      method: 'put',
      url: 'https://findparking-api.azurewebsites.net/singleUpdateStatus',
      data: {
        building: obj.building,
        floor: obj.floor,
        status: obj.isAvailable ? 0 : 1
      }
    })
  }

  return (

    <div className="App">
      <ThemeProvider theme={theme}>
        <Container style={{ paddingTop: "90px", paddingBottom: "80px" }}>
          {/* ========================== MOBILE ========================== */}
          <Grid item sx={{ display: { sm: 'none', xs: 'block' } }} align="center" xs={12}>
            <Grid container spacing={2} >
              <Grid item align="center" xs={12}>
                <Paper
                  elevation={0}
                  style={{
                    width: '80%',
                    padding: '20px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    backgroundColor: '#C2C2C2',
                    borderRadius: '15px'
                  }} >
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <Typography variant="h5"
                        style={{ fontWeight: 700, color: 'white' }}
                      >
                        Level
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h5"
                        style={{ fontWeight: 700, color: 'white' }}
                      >
                        Available
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {isLoaded && items.map((obj, index) =>
                <Grid key={`mobile-${index}`} item align="center" xs={12}>
                  <Paper
                    elevation={0}
                    style={{
                      width: '80%',
                      padding: '20px',
                      paddingTop: '30px',
                      paddingBottom: '30px',
                      backgroundColor: '#EBEBEB',
                      borderRadius: '15px'
                    }} >
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Typography variant="h4"
                          style={{ fontWeight: 700 }}
                        >
                          {obj.floor === "P4" ? "P4 & P5" : obj.floor}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          disabled
                          disableElevation
                          variant="contained"
                          style={{
                            color: "white",
                            backgroundColor: obj.currentslots <= 0 ? "#D1D1D1" : statusColor(obj.currentslots, obj.totalslots)  /*obj.currentslots === 0 ? "#B83232" : "#81C799"*/,
                            width: '100px',
                            borderRadius: '10px'
                          }}
                        >
                          <Typography variant="h5" style={{ fontWeight: 600 }}>
                            {obj.currentslots <= 0 ? "Full" : (obj.currentslots < 5 ? "<5" : obj.currentslots)}
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )}

              {isLoaded &&
                <Grid item align="right" xs={12} style={{ paddingRight: '7%', paddingTop: '5px', color: '#C2C2C2' }}>
                  <Typography variant="body2" style={{ fontWeight: 600 }}>
                    {`Last activity ${moment(latestUpdate).startOf('minutes').fromNow() === 'a few seconds ago' ? 'less than 1 minute ago' : moment(latestUpdate).startOf('minutes').fromNow()}`}
                  </Typography>
                </Grid>
              }

              {!isLoaded &&
                <Grid item align="center" xs={12}>
                  <Skeleton variant="rounded" width={'90%'} height={110} style={{ borderRadius: '15px' }} />
                </Grid>
              }

              {/*P4&5 input slot*/}
              <Grid container direction="row" justifyContent="center" alignItems="center" xs={9}>
                <Grid xs={11} >
                  <InputContainer
                    slotCountInput={slotCountInputP4}
                    setSlotCountInput={setSlotCountInputP4}
                    isSlotCountInputEmpty={isSlotCountInputEmpty}
                    onChageSlotCount={onChageSlotCount}
                    floorNumber={'P4'}
                  />
                  <InputContainer
                    slotCountInput={slotCountInputP5}
                    setSlotCountInput={setSlotCountInputP5}
                    isSlotCountInputEmpty={isSlotCountInputEmpty}
                    onChageSlotCount={onChageSlotCount}
                    floorNumber={'P5'}
                  />
                </Grid>
                <Grid xs={1}>
                  <Button
                    disableElevation
                    disabled={slotCountInputP4 > 0 && slotCountInputP5 > 0 ? false : true}
                    variant="contained"
                    onClick={() => handleOpenDialog([{
                      number: 'P4',
                      slotCountInput: slotCountInputP4
                    }, {
                      number: 'P5',
                      slotCountInput: slotCountInputP5
                    }])}
                    style={{ height: "55px", borderRadius: "5px", backgroundColor: "#CE0A0A", marginTop: "8px" }}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
              {/* B2 input slot */}
              <Grid marginTop="8px" container direction="row" justifyContent="center" alignItems="center" xs={9}>
                <Grid xs={11}>
                  <InputContainer
                    slotCountInput={slotCountInputB2}
                    setSlotCountInput={setSlotCountInputB2}
                    isSlotCountInputEmpty={isSlotCountInputEmpty}
                    onChageSlotCount={onChageSlotCount}
                    floorNumber={'B2'}
                  />
                </Grid>
                <Grid xs={1} paddingTop="16px">
                  <Button
                    disableElevation
                    disabled={slotCountInputB2 > 0 && slotCountInputB2 ? false : true}
                    variant="contained"
                    onClick={() => handleOpenDialog([{
                      number: 'B2',
                      slotCountInput: slotCountInputB2
                    }])}
                    style={{ height: "55px", borderRadius: "5px", backgroundColor: "#CE0A0A" }}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* ========================== WEB ========================== */}
          <Grid item sx={{ display: { xs: 'none', sm: 'block' } }} xs={12}>
            <Grid container justifyContent="center" spacing={2} >
              <Grid item align="center" xs={12}>
                <Paper
                  elevation={0}
                  style={{
                    width: '70%',
                    padding: '20px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    backgroundColor: '#C2C2C2',
                    borderRadius: '15px'
                  }} >
                  <Grid item xs={12}>
                    <Typography variant="h4"
                      style={{ fontWeight: 700, color: 'white' }}
                    >
                      Update Slot Count
                    </Typography>
                  </Grid>
                  <Grid item padding={2} onClick={() => { }}>
                    <Button variant="h6"
                      style={{ fontWeight: 700, color: 'Black', background: 'white' }}
                      onClick={() => {
                        let d = new Date()
                        exportExcel([generateReportData(reportData)], [headerReport], ['sheet1'], formatDate(d, 'en'))
                      }}
                    >
                      Generate Report
                    </Button>
                    <Button variant="h6"
                      style={{ fontWeight: 700, color: 'Black', background: 'white', marginLeft: '1rem'}}
                      onClick={() => {
                        generateLogFileData()
                      }}
                    >
                       3months log report
                    </Button>
                    {/* <ExcelFile fileExtension='.csv'>
                      <ExcelSheet
                        data={reportData}
                        name="reportSheet"
                      />
                    </ExcelFile> */}
                  </Grid>
                </Paper>
              </Grid>

              {isLoaded && items.map((obj, index) =>
                <Grid key={`web-${index}`} item align="center" xs={12}>
                  <Paper
                    elevation={0}
                    style={{
                      width: '70%',
                      padding: '20px',
                      paddingTop: '30px',
                      paddingBottom: '30px',
                      backgroundColor: '#EBEBEB',
                      borderRadius: '15px'
                    }} >
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Typography variant="h4"
                          style={{ fontWeight: 700 }}
                        >
                          {obj.floor === "P4" ? "P4&P5" : obj.floor}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container>
                          <Grid item xs>
                            <Button
                              disabled
                              disableElevation
                              variant="contained"
                              style={{
                                color: "white",
                                backgroundColor: obj.currentslots <= 0 ? "#D1D1D1" : statusColor(obj.currentslots, obj.totalslots),
                                width: '100px',
                                borderRadius: '10px'
                              }}
                            >
                              <Typography variant="h5" style={{ fontWeight: 600 }}>
                                {obj.currentslots <= 0 ? "Full" : (obj.currentslots < 5 ? "<5" : obj.currentslots)}
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid align="left" item xs>
                            <FormControlLabel control={<Switch onChange={() => { onClickAvilableSwitch(obj) }} checked={obj.isAvailable} />} label={obj.isAvailable ? "Available" : "Unavailable"} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )}

              {isLoaded &&
                <Grid item align="right" xs={12} style={{ paddingRight: '14%', paddingTop: '5px', color: '#C2C2C2' }}>
                  <Typography variant="body1" style={{ fontWeight: 600 }}>
                    {`Last activity ${moment(latestUpdate).startOf('minutes').fromNow() === 'a few seconds ago' ? 'less than 1 minute ago' : moment(latestUpdate).startOf('minutes').fromNow()}`}
                  </Typography>
                </Grid>
              }

              {!isLoaded &&
                <Grid item align="center" xs={12}>
                  <Skeleton variant="rounded" width={'73.5%'} height={105} style={{ borderRadius: '15px' }} />
                </Grid>
              }
              <Grid container direction="row" justifyContent="center" alignItems="center" xs={8}>
                <Grid xs={10}>
                  <InputContainer
                    slotCountInput={slotCountInputP4}
                    setSlotCountInput={setSlotCountInputP4}
                    isSlotCountInputEmpty={isSlotCountInputEmpty}
                    onChageSlotCount={onChageSlotCount}
                    floorNumber={'P4'}
                  />
                  <InputContainer
                    slotCountInput={slotCountInputP5}
                    setSlotCountInput={setSlotCountInputP5}
                    isSlotCountInputEmpty={isSlotCountInputEmpty}
                    onChageSlotCount={onChageSlotCount}
                    floorNumber={'P5'}
                  />
                </Grid>
                <Grid xs={2}>
                  <Button
                    disableElevation
                    disabled={slotCountInputP4 > 0 && slotCountInputP5 > 0 ? false : true}
                    variant="contained"
                    onClick={() => handleOpenDialog([{
                      number: 'P4',
                      slotCountInput: slotCountInputP4
                    }, {
                      number: 'P5',
                      slotCountInput: slotCountInputP5
                    }])}
                    style={{ height: "55px", borderRadius: "5px", backgroundColor: slotCountInputP4 > 0 && slotCountInputP5 > 0 ? "#CE0A0A" : "", marginTop: "8px" }}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
              {/* B2 input slot */}
              <Grid marginTop="8px" container direction="row" justifyContent="center" alignItems="center" xs={8}>
                <Grid xs={10}>
                  <InputContainer
                    slotCountInput={slotCountInputB2}
                    setSlotCountInput={setSlotCountInputB2}
                    // isSlotCountInputEmpty={isSlotCountInputEmpty}
                    onChageSlotCount={onChageSlotCount}
                    floorNumber={'B2'}
                  />
                </Grid>
                <Grid xs={2} paddingTop="16px">
                  <Button
                    disableElevation
                    disabled={slotCountInputB2 > 0 && slotCountInputB2 ? false : true}
                    variant="contained"
                    onClick={() => handleOpenDialog([{
                      number: 'B2',
                      slotCountInput: slotCountInputB2
                    }])}
                    style={{ height: "55px", borderRadius: "5px", backgroundColor: slotCountInputB2 > 0 && slotCountInputB2 ? "#CE0A0A" : "" }}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/*=========================== DIALOGS ===========================*/}
          <Dialog
            open={isOpenDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Please enter passcode"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        type="password"
                        onChange={onChagePasscodeInput}
                        style={{ backgroundColor: "white" }}
                        error={isIncorrectPasscode}
                        helperText={isIncorrectPasscode ? "Incorrect passcode" : ""}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <LoadingButton onClick={handleVerifyPasscode} loading={isUpdating} autoFocus>
                Submit
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Container>
      </ThemeProvider>
    </div >
  );
}

export default UpdateSlotCount;