import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

export default function NavigationBar() {
    return (
        <AppBar position="static" style={{ backgroundColor: "#CE0A0A" }}>
            <Toolbar>
                <Typography
                    variant="h4"
                    component="div"
                    sx={{ flexGrow: 1 }}
                    style={{ fontWeight: 600, padding: '25px' }}
                >
                    FindParking
                </Typography>
            </Toolbar>
        </AppBar>
    );
}