import React, { useEffect } from 'react';
import { Chart, Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, Title, ChartOptions  } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom'
import 'chartjs-adapter-moment';

ChartJS.register(zoomPlugin);
export const LineChart = ({ data: data }) => {
    let a = {
       scales: {
            x: {
                type: 'time',
            }, 
        },
    }

    useEffect(() => {
        console.log(data)
    },[data])

  return (
    <div style={{
        width: '100%'
    }}>
    {data? 
      <Line
        data={data}
        options={a}
      />
      : <></>}
    </div>
  );
};
