import './App.css';

import React, { useState,useEffect } from 'react';

//Material UI
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NavigationBar from './components/navbar/NavigationBar';

//Constants
import * as apiConstants from '../src/constants/apiConstants';

//Router
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

//Others

//Import pages
import HomePage from './components/pages/HomePage';
import UpdateSlotCount from './components/pages/UpdateSlotCount';
import UnderConstructionPage from './components/pages/UnderConstructionPage';
import ChartPage from './components/pages/ChartPage';

const theme = createTheme({
  typography: {
    fontFamily: [
      "Poppins"
    ].join(",")
  }
});

function App() {
  const [isUnderConstruction, setIsUnderConstruction] = useState(false);
  const [floorStatus, setFloorStatus] = useState([]);

  useEffect(() => {
    fetch(`${apiConstants.FINDPARKING_API_HOSTNAME}/floorstatus/HRH`, { crossDomain: true })
        .then(res =>res.json())
      .then((result) => setFloorStatus(result))
      
}, [])

  useEffect(() => {
    //checkIsUnderConstruction();
    // if (window.location.pathname === "/updateslot") {
    //   window.location.replace('https://findparking.smartoffice.xom.cloud/updateslot');
    // } else {
    //   window.location.replace('https://findparking.smartoffice.xom.cloud');
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[floorStatus])


  // const checkIsUnderConstruction = () => {
  //   // remove B1 floor
  //   floorStatus.pop()

  //   const isAvailable = floorStatus.map((x) => x.isAvailable).some((i) => i === 0)
  //   setIsUnderConstruction(isAvailable);
  // }

  const router = createBrowserRouter([
    {
      path: "/",
      element: isUnderConstruction ? <UnderConstructionPage /> : <HomePage />
    },
    {
      path: "/updateslot",
      element: <UpdateSlotCount setIsUnderConstruction={setIsUnderConstruction} isUnderConstruction={isUnderConstruction} />
    },
    {
      path: "/chart",
      element: <ChartPage />
    }
  ]);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <NavigationBar />
        <RouterProvider
          router={router}
          fallbackElement={isUnderConstruction ? <UnderConstructionPage /> : <HomePage />}
        />
      </ThemeProvider>
    </div >
  );
}

export default App;
