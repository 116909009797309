import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'

export function exportExcel(
	excelData,
	header,
	sheetNameList,
	fileName,
) {
	const fileType = 'application/vnd.ms-excel;charset=utf-8'
	const fileExtension = '.xlsx'
	//create excel work book (main excel file)
	const wb = XLSX.utils.book_new()
	//loop through each sheet
	sheetNameList.forEach((sheetName, index) => {
		//filter column of excelData based on header
		const jsonData = excelData[index].map((data) => {
			const obj = {}
			header[index].forEach((eachHeader) => {
				obj[eachHeader.label] = data[eachHeader.value]
			})
			return obj
		})
		//convert json to work sheet
		const ws = XLSX.utils.json_to_sheet(jsonData)
		//put work sheet into work book
		XLSX.utils.book_append_sheet(wb, ws, sheetName)
	})
	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
	//convert to actual file
	const data = new Blob([excelBuffer], { type: fileType })
	saveAs(data, fileName + fileExtension)
}
