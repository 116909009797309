export const headerReport = [
    {
      label: "date",
      value: "date"
    },
    {
      label: "time",
      value: "time"
    },
    {
      label: "building",
      value: "building"
    },
    {
      label: "countslots",
      value: "countslots"
    },
    {
      label: "floor",
      value: "floor"
    }, {
      label: "percentage",
      value: "percentage"
    },
  ]

export const headerLogReport = [
    {
        label: "building",
        value: "building"
      },
      {
        label: "currentslots",
        value: "currentslots"
      },
      {
        label: "floor",
        value: "floor"
      },
      {
        label: "in",
        value: "in"
      },
      {
        label: "out",
        value: "out"
      },
      {
        label: "timestamp",
        value: "timestamp"
      }
]