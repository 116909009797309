import React from 'react';

//Material UIs
import Grid from '@mui/material/Grid';
import { TextField, Typography } from '@mui/material';


export const InputContainer = (props) => {
    const onChangeInput = (event) => {
        props.setSlotCountInput(event.target.value < 0 ? 0 : event.target.value);
    }

    return (
        <Grid container justifyContent="center" alignItems="center" xs={props.xs} spacing={2} paddingTop={2}>
            <Grid item alignContent="center" xs={2} height="100%">
                <Typography 
                        variant="h6"
                        style={{ fontWeight: 600, color: 'Black' }}
                        >
                        {props.floorNumber}
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="number"
                    value={props.slotCountInput}
                    // error={props.isSlotCountInputEmpty}
                    // helperText={props.isSlotCountInputEmpty ? "Input is empty" : ""}
                    onChange={onChangeInput}
                    style={{ backgroundColor: "white" }}
                    InputProps={{
                        inputProps: { min: 0 }
                    }}
                    fullWidth
                />
            </Grid>
        </Grid>)
}